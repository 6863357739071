import Vue from 'vue/dist/vue.esm'

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('user-invoice-form-vue');
  if (element === null) {
    return
  }

  new Vue({
    el: "#user-invoice-form-vue",
    data: function (){
      return {
        invoice: {},
        sameAsConsignee: true
      }
    },
    mounted: function(){
      const container = this.$el;//<div id="admin-invoice-form-vue">

      // セキュリティのため、変数 invoice には Invoiceモデルの一部のカラムしか取得していません。
      // カラムを追加したい場合は、app/views/admin/invoices/_form.html.slim ファイルの@invoice.to_jsonメソッドの引数を書き足してください。
      this.invoice = JSON.parse(container.getAttribute('data-invoice'));
      const someFieldHasValue = [
        this.invoice['notify_party_username'],
        this.invoice['notify_party_country'],
        this.invoice['notify_party_city'],
        this.invoice['notify_party_address'],
        this.invoice['notify_party_tel'],
        this.invoice['notify_party_fax'],
        this.invoice['notify_party_email'],
      ].some((value)=>Boolean(value))
      this.sameAsConsignee = !someFieldHasValue;
    },
    methods: {
      onChangeSameAsConsignee: function () {
        if(this.sameAsConsignee){
          this.invoice['notify_party_username'] = '';
          this.invoice['notify_party_country'] = '';
          this.invoice['notify_party_city'] = '';
          this.invoice['notify_party_address'] = '';
          this.invoice['notify_party_tel'] = '';
          this.invoice['notify_party_fax'] = '';
          this.invoice['notify_party_email'] = '';
        }
      }
    }
  })

});
import Vue from 'vue/dist/vue.esm'
import axios from 'axios'
import qs from 'qs';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

const ORDINAL_NUMBERS = [
  {number: 1, text:'first', shortText: '1st'},
  {number: 2, text:'second', shortText: '2nd'},
  {number: 3, text:'third', shortText: '3rd'},
  {number: 4, text:'fourth', shortText: '4th'},
  {number: 5, text:'fifth', shortText: '5th'},
];

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('admin-invoice-form-vue');
  if (element === null) {
    return
  }

  new Vue({
    el: "#admin-invoice-form-vue",
    data: function (){
      return {
        invoice: {},
        currentAdminUser: {},
        unacceptablePrices: null,
        toPortOptions: [['選択して下さい', '']],
        loading: false,
        have_port_freight_error: false,
        breakdown: false,
        userSearch: {
          modalInstance: null,
          users: [],
          q: {}
        },
        consigneeSearch: {
          modalInstance: null,
          users: [],
          q: {}
        },
        countriesInspection: {},
        countriesCfs: {},
        inspectionNotice: false,
        cfsNotice: false,
        lowestPercentage:null,//一回目の支払い最低パーセンテージ
        paymentLimitsExcluded:false,
        totalPriceChanged: false,
        firstPriceChanged: false,
      }
    },
    mounted: function(){
      const container = this.$el;//<div id="admin-invoice-form-vue">

      //app/views/admin/invoices/_form.html.slim の div#admin-invoice-form-vue 要素で設定
      const properties = JSON.parse(container.getAttribute('data-properties-json'));
      this.invoice = properties['invoice'];
      this.currentAdminUser = properties['current_admin_user'];//現状、role属性のみ取得

      // inspection を基本必要とする国のリスト
      this.countriesInspection = JSON.parse(document.getElementById('countries-inspection-list').getAttribute('data-inspection'));

      // cfs を基本必要とする国のIDのリスト（文字列リストからIDリストを生成）
      var countryNamesCfs = ['KENYA'];
      var countryNames = JSON.parse(document.getElementById('countries-name-list').getAttribute('data-inspection'));
      var tmpList = [];
      countryNamesCfs.forEach(function (countryName) {
        var target = countryNames.filter(e => e[0] === countryName);
        if (typeof target[0] !== 'undefined' && typeof target[0][1] !== 'undefined') {
          tmpList.push(target[0][1]);
        }
      });
      this.countriesCfs = tmpList;

      if (this.hasBreakdown()){
        this.breakdown = true;
      }

      //空文字列にしなければ<option value>選択して下さい</option>が表示されない問題を修正
      Object.keys(this.invoice).forEach((key)=>{
        if (this.invoice[key] === null) {
          this.invoice[key] = '';
        }
      })

      this.getToPortOptions();
      this.getUnacceptablePrices();
      this.checkInspectionAndCfs();
      this.getPaymentLevelInfo();
      
      if (this.invoice[`total_price`]==""){
        this.invoice[`total_price`]="0"
      }
    },
    beforeDestroy(){//インスタンスが破棄される前に
      if (this.userSearch.modalInstance){
        $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
        this.userSearch.modalInstance.modal('dispose');
      }
    },
    watch:{
      'invoice.number_of_payments': function(){
        this.setDefaultDue();
        this.clearPaymentFieldForOverTimes();
      },
    },
    computed:{
      currency: function () {
        const currencies = {'dollar': {suffix: '', code: 'USD'}, 'yen':{suffix: '_yen', code: 'JPY'}, 'btc':{suffix: '_btc', code: 'BTC'}, 'xrp':{suffix: '_xrp', code: 'XRP'}};
        if (!this.invoice['currency']){
            return currencies.dollar
        }
        return currencies[this.invoice['currency']];
      },
      totalPrice: {
        get: function (){
          return this.invoice[`total_price${this.currency.suffix}`];
        },
        set: function (price){
          this.invoice[`total_price${this.currency.suffix}`] = price;
        },
      },
      firstPrice: {
        get: function (){
          return this.invoice[`first_price${this.currency.suffix}`];
        },
        set: function (price){
          this.invoice[`first_price${this.currency.suffix}`] = price;
        },
      },
      secondPrice: {
        get: function (){
          return this.invoice[`second_price${this.currency.suffix}`];
        },
        set: function (price){
          this.invoice[`second_price${this.currency.suffix}`] = price;
        },
      },
      thirdPrice: {
        get: function (){
          return this.invoice[`third_price${this.currency.suffix}`];
        },
        set: function (price){
          this.invoice[`third_price${this.currency.suffix}`] = price;
        },
      },
      fourthPrice: {
        get: function (){
          return this.invoice[`fourth_price${this.currency.suffix}`];
        },
        set: function (price){
          this.invoice[`fourth_price${this.currency.suffix}`] = price;
        },
      },
      fifthPrice: {
        get: function (){
          return this.invoice[`fifth_price${this.currency.suffix}`];
        },
        set: function (price){
          this.invoice[`fifth_price${this.currency.suffix}`] = price;
        },
      },
      installmentPrice() {
    
        //通貨で小数点を使う場合、ズレを防ぐ為、一旦整数にしてから計算
        if(this.currency.code == "BTC"||this.currency.code == "XRP"){
          // それぞれの小数点の位置を取得
          var dotPosition1 = this.getDotPosition(this.firstPrice);
          var dotPosition2 = this.getDotPosition(this.secondPrice);
          var dotPosition3 = this.getDotPosition(this.thirdPrice);
          var dotPosition4 = this.getDotPosition(this.fourthPrice);
          var dotPosition5 = this.getDotPosition(this.fifthPrice);
       
          // 位置の値が大きい方（小数点以下の位が多い方）の位置を取得
          var max = Math.max(dotPosition1,dotPosition2,dotPosition3,dotPosition4,dotPosition5);
       
          // 大きい方に小数の桁を合わせて文字列化、
          // 小数点を除いて整数の値にする
          var intValue1 = parseInt((this.firstPrice.toFixed(max) + '').replace('.', ''));
          var intValue2 = parseInt((this.secondPrice.toFixed(max) + '').replace('.', ''));
          var intValue3 = parseInt((this.thirdPrice.toFixed(max) + '').replace('.', ''));
          var intValue4 = parseInt((this.fourthPrice.toFixed(max) + '').replace('.', ''));
          var intValue5 = parseInt((this.fifthPrice.toFixed(max) + '').replace('.', ''));

          // 10^N の値を計算
          var power = Math.pow(10,max);
       
          // 整数値で引き算した後に10^Nで割る
          return (intValue1 + intValue2 + intValue3 + intValue4 + intValue5) / power;
       }else{
          return this.firstPrice + this.secondPrice + this.thirdPrice + this.fourthPrice + this.fifthPrice;
         }
      },
      errors: function() {
        const errors = {};

        //特定の金額以下 では発行できないようにする。ただし super_user はこの制限は不要
        if (this.unacceptablePrices && this.unacceptablePrices[`price${this.currency.suffix}`] && this.currentAdminUser?.['role'] !== 'super_user' && this.totalPriceChanged) {
          const unacceptablePrice = this.unacceptablePrices[`price${this.currency.suffix}`];
          if (this.totalPrice <= unacceptablePrice){
            errors['totalPrice'] = `This invoice must be issued for the amount greater than ${this.currency.code}${unacceptablePrice}. `;
          }
        }

        //分割払いのバリデーション
        if (2 <= this.invoice['number_of_payments']) {
          if (this.totalPrice != this.installmentPrice) {
            ORDINAL_NUMBERS.forEach((ordinalNumber)=>{
              //errors[`${ordinalNumber.text}Price`] = 'Sum of installment prices should equal to total price. ';
              errors[`${ordinalNumber.text}Price`] = `トータルプライス${this.totalPrice} 合計${this.installmentPrice}`;
            })
          }

          ORDINAL_NUMBERS.forEach((ordinalNumber)=>{
            const prev = ORDINAL_NUMBERS.find( item => item.number == ordinalNumber.number-1)

            if (ordinalNumber.number > this.invoice['number_of_payments']) {
              return;
            }

            if (this[`${ordinalNumber.text}Price`] == 0){
              errors[`${ordinalNumber.text}Price`] = 'Price is required.';
            }

            if (this.invoice[`${ordinalNumber.text}_due`] == 0){
              errors[`${ordinalNumber.text}_due`] = 'Due is required.';
            }

            if (ordinalNumber.number >= 3) {
              if (this.invoice[`${ordinalNumber.text}_due`] < this.invoice[`${prev.text}_due`]){
                errors[`${ordinalNumber.text}_due`] = `${ordinalNumber.shortText} due must greater than ${prev.shortText} due.`;
              }
            }
          })

          // 分割払いの1回目の金額に対する下限制限 issue3536
          // 変更操作を行わない限りは、以下の制限処理は行わない
          // (価格制限機能リリース前のインボイスを編集する際に、金額以外の名前などの項目も更新できなくなるのを防ぐ措置。)
          if(this.firstPriceChanged){
            //1回目の支払いは、totalPriceの n% 以上である事
            if (this.firstPrice && this.firstPrice < parseInt(this.totalPrice * (this.lowestPercentage / 100.0))){
              errors['firstPrice']  = `This user must make a 1st payment of at least ${this.lowestPercentage}%.`;
            }
          }
        }

        // 内訳の任意項目のバリデーション
        [1,2,3].forEach( num =>{ if(this.invoice[`optional_cost_${num}_value`] && !this.invoice[`optional_cost_${num}_key`]){
          errors[`optional_cost_${num}_key`] = "Please input optional cost name here."
        }})

        return errors;
      },
      hasErrors: function(){
        return Object.keys(this.errors).length > 0;
      },
      disabled: function(){
        return this.loading || this.hasErrors || this.have_port_freight_error;
      }
    },
    methods: {
      clickUserIdButton: async function (){
        const user_id = this.invoice['user_id']
        if(user_id != ""){
          try{
            const result = await axios.get(`/admin/api/search_user/${this.invoice['user_id']}`)
            this.invoice['to_country_id'] = result.data['country_id']
            this.getToPortOptions()
            this.invoice['to_port_id'] = result.data['port_id']
            this.invoice['cfs'] = result.data['cfs_key']
            this.getUnacceptablePrices();
          }catch (e){
            console.log(e)
          }
        }
      },
      onClickChooseUser: function(userId){
        this.invoice['user_id'] = userId;
        this.hideUserSearchModal();
        this.getPaymentLevelInfo();
      },
      //過去のConsigneeからConsigneeに値を入れる
      onClickFillConsignee2: async function(id){
        const result = await axios.get(`/admin/api/search_consignee/${id}`)
        const user = result.data
        console.log(user)
        if (!user) {
          return
        }
        this.invoice['username'] = user['username'];
        this.invoice['address'] = user['address'];
        this.invoice['country'] = user['country'];
        this.invoice['city'] = user['city'];
        this.invoice['tel'] = user['tel'];
        this.invoice['fax'] = user['fax'];
        this.invoice['email'] = user['email'];
        this.hideConsigneeSearchModal();
      },
      //過去のConsigneeからNotifyPartyに値を入れる
      onClickFillConsignee3: async function(id){
        const result = await axios.get(`/admin/api/search_consignee/${id}`)
        const user = result.data
        console.log(user)
        if (!user) {
          return
        }
        this.invoice['notify_party_username'] = user['username'];
        this.invoice['notify_party_country'] = user['country'];
        this.invoice['notify_party_city'] = user['city'];
        this.invoice['notify_party_address'] = user['address'];
        this.invoice['notify_party_tel'] = user['tel'];
        this.invoice['notify_party_fax'] = user['fax'];
        this.invoice['notify_party_email'] = user['email'];
        this.hideConsigneeSearchModal();
      },
      //過去のConsigneeからDHLに値を入れる
      onClickFillConsignee4: async function(id){
        const result = await axios.get(`/admin/api/search_consignee/${id}`)
        const user = result.data
        console.log(user)
        if (!user) {
          return
        }
        this.invoice['dhl_username'] = user['username'];
        this.invoice['dhl_country_id'] = "";
        this.invoice['dhl_address'] = user['address'];
        this.invoice['dhl_city'] = "";
        this.invoice['dhl_postal_code'] = "";
        this.invoice['dhl_tel'] = user['tel'];
        this.invoice['dhl_email'] = user['email'];
        this.hideConsigneeSearchModal();
      },


      //過去のNotify PartyからConsigneeに値を入れる
      onClickFillNotifyParty2: async function(id){
        const result = await axios.get(`/admin/api/search_consignee/${id}`)
        const user = result.data
        console.log(user)
        if (!user) {
          return
        }
        this.invoice['username'] = user['notify_party_username'];
        this.invoice['country'] = user['notify_party_country'];
        this.invoice['city'] = user['notify_party_city'];
        this.invoice['address'] = user['notify_party_address'];
        this.invoice['tel'] = user['notify_party_tel'];
        this.invoice['fax'] = user['notify_party_fax'];
        this.invoice['email'] = user['notify_party_email'];
        this.hideConsigneeSearchModal();
      },
      //過去のNotify PartyからNotifyPartyに値を入れる
      onClickFillNotifyParty3: async function(id){
        const result = await axios.get(`/admin/api/search_consignee/${id}`)
        const user = result.data
        console.log(user)
        if (!user) {
          return
        }
        this.invoice['notify_party_username'] = user['notify_party_username'];
        this.invoice['notify_party_country'] = user['notify_party_country'];
        this.invoice['notify_party_city'] = user['notify_party_city'];
        this.invoice['notify_party_address'] = user['notify_party_address'];
        this.invoice['notify_party_tel'] = user['notify_party_tel'];
        this.invoice['notify_party_fax'] = user['notify_party_fax'];
        this.invoice['notify_party_email'] = user['notify_party_email'];
        this.hideConsigneeSearchModal();
      },
      //過去のNotify PartyからDHLに値を入れる
      onClickFillNotifyParty4: async function(id){
        const result = await axios.get(`/admin/api/search_consignee/${id}`)
        const user = result.data
        console.log(user)
        if (!user) {
          return
        }
        this.invoice['dhl_username'] = user['notify_party_username'];
        this.invoice['dhl_country_id'] = "";
        this.invoice['dhl_city'] = "";
        this.invoice['dhl_postal_code'] = "";
        this.invoice['dhl_address'] = user['notify_party_address'];
        this.invoice['dhl_tel'] = user['notify_party_tel'];
        this.invoice['dhl_email'] = user['notify_party_email'];
        this.hideConsigneeSearchModal();
      },

      onClickFillConsignee: async function(){
        const result = await axios.get(`/admin/api/search_user/${this.invoice['user_id']}`)
        const user = result.data
        console.log(user)
        if (!user) {
          return
        }
        this.invoice['username'] = user['username'];
        this.invoice['address'] = user['address'];
        this.invoice['tel'] = user['tel'];
        this.invoice['fax'] = user['fax'];
        this.invoice['email'] = user['email'];
      },
      onClickFillNotifyParty: async function(){
        const result = await axios.get(`/admin/api/search_user/${this.invoice['user_id']}`)
        const user = result.data
        if (!user) {
          return
        }
        this.invoice['notify_party_username'] = user['notify_party_username'];
        this.invoice['notify_party_country'] = user['notify_party_country'];
        this.invoice['notify_party_city'] = user['notify_party_city'];
        this.invoice['notify_party_address'] = user['notify_party_address'];
        this.invoice['notify_party_tel'] = user['notify_party_tel'];
        this.invoice['notify_party_fax'] = user['notify_party_fax'];
        this.invoice['notify_party_email'] = user['notify_party_email'];
      },
      onClickFilDhlInformation: async function(){
        const result = await axios.get(`/admin/api/search_user/${this.invoice['user_id']}`)
        const user = result.data
        if (!user) {
          return
        }
        this.invoice['dhl_username'] = user['dhl_username'];
        this.invoice['dhl_country_id'] = user['dhl_country_id'];
        this.invoice['dhl_city'] = user['dhl_city'];
        this.invoice['dhl_postal_code'] = user['dhl_postal_code'];
        this.invoice['dhl_address'] = user['dhl_address'];
        this.invoice['dhl_tel'] = user['dhl_tel'];
        this.invoice['dhl_fax'] = user['dhl_fax'];
        this.invoice['dhl_email'] = user['dhl_email'];
      },


      onClickFillDeliveryInformation: async function(){
        const result = await axios.get(`/admin/api/search_user/${this.invoice['user_id']}`)
        const user = result.data
        if (!user) {
          return
        }
        if (user['location_country_id'] != null) {
          this.invoice['to_country_id'] = user['location_country_id'];
        }else{
          this.invoice['to_country_id'] = user['country_id'];
        }
        await this.getToPortOptions()
        this.invoice['to_port_id'] = user['port_id'];
        this.invoice['final_destination_country_id'] = user['country_id'];
        this.invoice['cfs'] = user['cfs_key'];
        if(user['inspection'] == true){
          this.invoice['inspection'] = 'QISJ'
        }
      },
      onClickUserSearch: async function (){
        const result = await axios.get(
            '/admin/api/search_user2.json',
            {
              params: {
                q: this.userSearch.q
              },
              paramsSerializer: function(params) {
                return qs.stringify(params, {arrayFormat: 'brackets'})
              }
            }
        )
        this.userSearch.users = result.data;
      },
      onClickConsigneesSearch: async function (){
        const result = await axios.get(
          '/admin/api/search_consignees.json',
          {
            params: {
              q: this.consigneeSearch.q
            },
            paramsSerializer: function(params) {
              return qs.stringify(params, {arrayFormat: 'brackets'})
            }
          }
        )
        this.consigneeSearch.users = result.data;
      },
      //ユーザーidを変更する度に、payment_levelを取得し直す
      onChangeUserID: function (){
        this.getPaymentLevelInfo();
      },
      //トータルプライスの変更
      onChangeTotalPrice: function(){
        this.totalPriceChanged = true;
        this.getPaymentLevelInfo();
      },
      onChangeToCountry: function (){
        this.invoice['to_port_id'] = '';
        this.unacceptablePrices = null;
        this.getToPortOptions();
        this.checkInspectionAndCfs();
        $('.have_port_freight_error').html("");
      },
      onChangeToPort: function(){
        this.totalPriceChanged = true; // 船賃がasking priceに関わるので、total priceが変更されたとみなして、UnacceptablePrices再計算する。
        this.getUnacceptablePrices();
        const is_saleStaff = $("#invoice_sales_staff").val()
        if (is_saleStaff == 'true') {
          this.getPortfreights()
        }
      },
      onChangeInspection: function(){
        this.totalPriceChanged = true; // 輸出前検査がasking priceに関わるので、total priceが変更されたとみなして、UnacceptablePrices再計算する。
        this.getUnacceptablePrices();
        this.checkInspectionAndCfs();
      },
      onChangeCfs: function(){
        this.checkInspectionAndCfs();
      },
      onChangeIncoterms: function(){
        this.totalPriceChanged = true; // インコタームズがasking priceに関わるので、total priceが変更されたとみなして、UnacceptablePrices再計算する。
        this.getUnacceptablePrices();
      },
      onChangeCurrency: function(){
        const kinds = ['total', 'first', 'second', 'third', 'fourth', 'fifth'];
        const suffixes = ['', '_yen','_btc','_xrp'];
        kinds.forEach((kind)=>{
          suffixes.forEach((suffix)=>{
            this.invoice[`${kind}_price${suffix}`] = 0;
          })
        })
        this.getPaymentLevelInfo();
      },
      onChangeFirstPrice: function() {
        this.firstPriceChanged = true;
        this.calcLastPayment();
      },
      onChangeSecondPrice: function() {
        this.calcLastPayment();
      },
      onChangeThirdPrice: function() {
        this.calcLastPayment();
      },
      onChangeFourthPrice: function() {
        this.calcLastPayment();
      },
      onChangeFifthPrice: function() {
        this.calcLastPayment();
      },
      // number_of_payments が2以上で最期の値のみ設定されていない場合自動的に値を設定する
      calcLastPayment: function() {
        const number = this.invoice['number_of_payments'];
        let remainPrice = this.totalPrice;
        switch (number) {
          case 2:
            if (this.firstPrice && !this.secondPrice) {
              remainPrice -= this.firstPrice;
              if (remainPrice > 0) {
                this.secondPrice = remainPrice;
              }
            }
            break;
          case 3:
            if (this.firstPrice && this.secondPrice && !this.thirdPrice) {
              remainPrice -= this.firstPrice + this.secondPrice;
              if (remainPrice > 0) {
                this.thirdPrice = remainPrice;
              }
            }
            break;
          case 4:
            if (this.firstPrice && this.secondPrice && this.thirdPrice && !this.fourthPrice) {
              remainPrice -= this.firstPrice + this.secondPrice + this.thirdPrice;
              if (remainPrice > 0) {
                this.fourthPrice = remainPrice;
              }
            }
            break;
          case 5:
            if (this.firstPrice && this.secondPrice && this.thirdPrice && this.fourthPrice && !this.fifthPrice) {
              remainPrice -= this.firstPrice + this.secondPrice + this.thirdPrice + this.fourthPrice;
              if (remainPrice > 0) {
                this.fifthPrice = remainPrice;
              }
            }
            break;
        }
        this.clearPaymentFieldForOverTimes();
      },
      //小数点の位置を調べる
      getDotPosition(value){
        // 数値のままだと操作できないので文字列化する
        var strVal = String(value);
        var dotPosition = 0;
          //小数点が存在するか確認
          if(strVal.lastIndexOf('.') !== -1){
            // 小数点があったら位置を取得
            dotPosition = (strVal.length-1) - strVal.lastIndexOf('.');
          }
          return dotPosition;
      },

      onChangeBreakdown: function(){
        if (this.breakdown == false) {
          this.invoice['fob'] = 0;
          this.invoice['freight_cost'] = 0;
          this.invoice['inspection_cost'] = 0;
          this.invoice['insurance_cost'] = 0;
          this.invoice['optional_cost_1_key'] = null;
          this.invoice['optional_cost_2_key'] = null;
          this.invoice['optional_cost_3_key'] = null;          
          this.invoice['optional_cost_1_value'] = null;
          this.invoice['optional_cost_2_value'] = null;
          this.invoice['optional_cost_3_value'] = null;
        }
      },
      hasBreakdown(){
        if (
          this.invoice['fob'] > 0 ||
          this.invoice['freight_cost'] > 0 ||
          this.invoice['inspection_cost'] > 0 ||
          this.invoice['insurance_cost'] > 0 ||
          this.invoice['optional_cost_1_value'] > 0 ||
          this.invoice['optional_cost_2_value'] > 0 ||
          this.invoice['optional_cost_3_value'] > 0
        ) {
          return true;
        } else {
          return false;
        }
      },
      div(){
        const lowest_percentage_price = parseInt(this.totalPrice * (this.lowestPercentage / 100.0)); // 総額のn%金額 #issue3536

        const number = this.invoice['number_of_payments'];
        const remainder = this.totalPrice % number;
        const equal_part = (this.totalPrice-remainder) / number;

        this.firstPrice = this.secondPrice = this.thirdPrice = this.fourthPrice = this.fifthPrice = equal_part;
        this.firstPrice += remainder;
      
        if (!this.paymentLimitsExcluded){
          // firstPriceが総額のn%以上ではない場合、再計算する
          if (this.firstPrice < lowest_percentage_price) {
            this.calculateLowestPercentagePrice_div(lowest_percentage_price);
          }
        }
        this.clearPaymentFieldForOverTimes();
      },
      calculateLowestPercentagePrice_div(lowest_percentage_price){
        const totalPrice_minus_after_thirty_percent = this.totalPrice - lowest_percentage_price; // 総額のn%を引いた残りの金額 issue#3286
        this.firstPrice = lowest_percentage_price; // 1回目の金額は必ず総額のn%を設定

        const number = (this.invoice['number_of_payments'] - 1) // 1回目の金額を設定したため、「-1」
        const remainder = totalPrice_minus_after_thirty_percent % number;
        const equal_part = (totalPrice_minus_after_thirty_percent-remainder) / number;
        
        this.secondPrice = this.thirdPrice = this.fourthPrice = this.fifthPrice = equal_part;
        this.firstPrice += remainder;
      },
      /**
       * Total priceを分割する(default 30%/70%)
       * @param ratios 分割比(例: [0.3, 0.7]は30%, 70%、[0.5, 0.3, 0.2]は50%, 30%, 20%)
       */
      divideByRatios(ratios = [0.3, 0.7]) {
        if (ratios.length > 5) {
          console.log('分割比は5つまでです。');
          return;
        }
        // 2023-08-15時点、1回目の支払いの下限（パーセンテージ）は30%(gold)or50%(other)
        const lowest_percentage_price = parseInt(this.totalPrice * (this.lowestPercentage / 100.0)); // 総額のn%金額 #issue3536
        const total = this.totalPrice;

        // totalPrice分割
        const prices = ratios.map(ratio => Math.floor(total * ratio));

        // 誤差修正（小数点の四捨五入による誤差）
        // 小数点演算の誤差(四捨五入の影響)で、分割された金額の合計がインボイスTotal priceと一致しないことがある。
        // prices.reduceを使用して分割された金額の合計を計算し、その合計を総額（Total price）から差し引いた誤差を計算する。
        // この誤差をPrice of 1st payment（prices[0]）に加えて分割された価格をTotal priceと一致させる。
        const initialValue = 0;
        const priceError = total - prices.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
        prices[0] += priceError; // 1回目支払い金額(Price of 1st payment)に誤差を加算

        if (!this.paymentLimitsExcluded){ // paymentLimitsExcluded == trueの場合は下限制限なし
          if (prices[0] < lowest_percentage_price) {
            // firstPriceが総額のn%(lowest_percentage_price)以上ではない場合、再計算する
            this.rebalancePricesFromNthPayment(prices, lowest_percentage_price);
          }
          if (this.lowestPrice && this.lowestPrice >= prices[0]){
            // firstPriceが下限金額以上ではない場合、再計算する
            this.rebalancePricesFromNthPayment(prices, this.lowestPrice);
          }
        }

        // 結果を各入力欄に反映
        [this.firstPrice, this.secondPrice, this.thirdPrice, this.fourthPrice, this.fifthPrice] = prices;

        this.clearPaymentFieldForOverTimes();
      },
      /**
       * 1回目の支払い金額(Price of 1st payment)が下限金額未満の場合、1回目の支払い金額を下限金額にする。
       * 1回目の支払い金額が増加した分の金額を最後の支払回から順に減らしていく。
       * @param prices 1st payment ~ Nth payment
       * @param targetPrice 下限金額
       */
      rebalancePricesFromNthPayment(prices, targetPrice) {
        let diff = targetPrice - prices[0]; // 下限金額と1回目の支払い金額の差分
        prices[0] = targetPrice; // 1回目の支払い金額を下限金額に設定

        // N回目の支払いから2回目の支払いまで順に、差分を減算していく
        // N回目で減算できない場合、N-1回目の支払いから減算する
        for (let i = prices.length - 1; i >= 1 && diff > 0; i--) {
          if (prices[i] > 0) {
            // 入力欄がマイナスになるのを防ぐため、減算する金額を入力欄の金額と比較して小さい方を減算する
            const subtractAmount = Math.min(prices[i], diff);
            prices[i] -= subtractAmount;
            diff -= subtractAmount;
          }
        }
      },
      setDefaultDue(){
        const defaultDue = 3;
        ORDINAL_NUMBERS
            .filter(ordinalNumber => ordinalNumber.number <= this.invoice['number_of_payments'] )
            .forEach((ordinalNumber)=>{
              if (this.invoice['number_of_payments'] != 1 && !this.invoice[`${ordinalNumber.text}_due`]) {
                this.invoice[`${ordinalNumber.text}_due`] = defaultDue;
              }
            })
      },
      clearPaymentFieldForOverTimes(){
        //例えば3回払いの場合、4回目以降の項目にゼロをセットする
        ORDINAL_NUMBERS
            .filter(ordinalNumber => ordinalNumber.number > this.invoice['number_of_payments'] )//例えば3回払いの場合、4回目以降の項目だけを処理対象とする
            .forEach((ordinalNumber)=>{
              this[`${ordinalNumber.text}Price`] = 0;
              this.invoice[`${ordinalNumber.text}_due`] = 0;
            })

        if (this.invoice['number_of_payments'] == 1) {
          //一括払いの場合はfirstPriceも不要。(totalPriceによって管理する)
          this[`firstPrice`] = 0;
        }
      },
      getToPortOptions: async function (){
        const ports = []
        ports.push(['選択して下さい', ''])

        if(!this.invoice['to_country_id']){
          return;
        }
        this.loading = true;
        try{
          const result = await axios.get(`/api/port/${this.invoice['to_country_id']}`);
          this.toPortOptions = ports.concat(result.data);
        }catch (e){
          console.log(e)
        }
        this.loading = false;
      },
      getUnacceptablePrices: async function(){
        this.unacceptablePrices = null;

        if (!this.invoice['to_port_id']){
          return;
        }

        this.loading = true;
        try{
          const result = await axios.get('/admin/invoices/api/unacceptable_price/', {
            params: {
              'id': this.invoice['id'],
              'car_history_id': this.invoice['car_history_id'],
              'to_port_id': this.invoice['to_port_id'],
              'inspection': this.invoice['inspection'],
              'incoterms': this.invoice['incoterms'],
            }
          })
          this.unacceptablePrices = result.data;
        }catch (error) {
          console.log(error);
        }
        this.loading = false;
      },
      // 対象portに船賃の設定がない場合エラー表示
      getPortfreights: async function(){
        if (!this.invoice['to_port_id']){
          return;
        }

        this.loading = true;
        try{
          const result = await axios.get('/admin/invoices/api/have_port_freights/', {
            params: {
              'to_port_id': this.invoice['to_port_id'],
              'car_history_id': this.invoice['car_history_id']
            }
          })
          const have_port_freight = result.data.have_freights
          if (have_port_freight) {
            this.have_port_freight_error = false;
            $('.have_port_freight_error').html("")
          }else{
            this.have_port_freight_error = true;
            $('.have_port_freight_error').html("There is no set shipping rate for this port. Please contact the sales maneger")
          }
          this.loading = false;
        }catch (error) {
          console.log(error);
        }
      },
      //ユーザーのpayment_lavel関連の情報を取得 issue3933
      getPaymentLevelInfo: async function (){
        const result = await axios.get(
          '/admin/api/get_payment_level_info.json',
          {
            params: {
              user_id: this.invoice['user_id'],
              currency: this.invoice['currency'],
              total_price: this.totalPrice
            },
          }
        )
        if (result.data.payment_limits_excluded){
           this.paymentLimitsExcluded = true //下限制限を必要としない
        }else{
          this.lowestPercentage = result.data.lowest_percentage //下限パーセンテージ
        }
      },

      showUserSearchModal(index) {
        this.userSearch.modalInstance = $(this.$refs.userSearchModal).modal({keyboard: false, show:true});
        const $modalBody = $('.modal-body', this.$refs.userSearchModal)
        console.log($modalBody)
        $modalBody.height($modalBody.height() + 1)
      },
      hideUserSearchModal() {
        if (this.userSearch.modalInstance){
          this.userSearch.modalInstance.modal('hide');
        }
      },
      showConsigneeSearchModal(index) {
        this.$set(this.consigneeSearch.q,"user_id_eq", this.invoice['user_id']);
        this.consigneeSearch.modalInstance = $(this.$refs.consigneeSearchModal).modal({keyboard: false, show:true});
        const $modalBody = $('.modal-body', this.$refs.consigneeSearchModal)
        $modalBody.height($modalBody.height() + 1)
        this.invoice['incoterms'] = index
      },
      hideConsigneeSearchModal() {
        if (this.consigneeSearch.modalInstance){
          this.consigneeSearch.modalInstance.modal('hide');
        }
      },
      showNotifyPartySearchModal(index) {
        this.$set(this.consigneeSearch.q,"user_id_eq", this.invoice['user_id']);
        this.consigneeSearch.modalInstance = $(this.$refs.notifyPartySearchModal).modal({keyboard: false, show:true});
        const $modalBody = $('.modal-body', this.$refs.notifyPartySearchModal)
        $modalBody.height($modalBody.height() + 1)
        this.invoice['incoterms'] = index
      },
      hideNotifyPartySearchModal() {
        if (this.consigneeSearch.modalInstance){
          this.consigneeSearch.modalInstance.modal('hide');
        }
      },
      showDHLSearchModal(index){
        this.$set(this.consigneeSearch.q,"user_id_eq", this.invoice['user_id']);
        this.consigneeSearch.modalInstance = $(this.$refs.dhlSearchModal).modal({keyboard: false, show:true});
        const $modalBody = $('.modal-body', this.$refs.dhlSearchModal)
        $modalBody.height($modalBody.height() + 1)
      },
      //過去のDHL情報からDHLに値を入れる
      onClickFillDhl: async function(id){
        const result = await axios.get(`/admin/api/search_consignee/${id}`)
        const user = result.data

        if (!user) {
          return
        }
        this.invoice['dhl_username'] = user['dhl_username'];
        this.invoice['dhl_country_id'] = user['dhl_country_id'];
        this.invoice['dhl_city'] = user['dhl_city'];
        this.invoice['dhl_address'] = user['dhl_address'];
        this.invoice['dhl_tel'] = user['dhl_tel'];
        this.invoice['dhl_email'] = user['dhl_email'];
        this.invoice['dhl_postal_code'] = user['dhl_postal_code'];
        this.hideConsigneeSearchModal();
      },
      //過去のDHL情報からConsigneeに値を入れる
      onClickFillDhl2: async function(id){
        const result = await axios.get(`/admin/api/search_consignee/${id}`)
        const user = result.data

        if (!user) {
          return
        }
        this.invoice['username'] = user['dhl_username'];
        this.invoice['address'] = user['dhl_address'];
        this.invoice['tel'] = user['dhl_tel'];
        this.invoice['fax'] = '';
        this.invoice['email'] = user['dhl_email'];
        this.hideConsigneeSearchModal();
      },
      //過去のDHL情報からNotifyPartyに値を入れる
      onClickFillDhl3: async function(id){
        const result = await axios.get(`/admin/api/search_consignee/${id}`)
        const user = result.data

        if (!user) {
          return
        }
        this.invoice['notify_party_username'] = user['dhl_username'];
        this.invoice['notify_party_address'] = user['dhl_address'];
        this.invoice['notify_party_tel'] = user['dhl_tel'];
        this.invoice['notify_party_fax'] = '';
        this.invoice['notify_party_email'] = user['dhl_email'];
        this.hideConsigneeSearchModal();
      },

      checkInspectionAndCfs() {
        // final_destination_country_id が設定されていればそちらで、なければ to_country_id で
        var country_id = this.invoice['final_destination_country_id'];
        if (!country_id) {
          country_id = this.invoice['to_country_id'];
        }

        // inspection が未設定かつ country_id が countriesInspection に含まれる場合は表示
        if (!this.invoice['inspection'] && this.countriesInspection.includes(Number(country_id))) {
          this.inspectionNotice = true;
        } else {
          this.inspectionNotice = false;
        }

        // inspection が未設定かつ to_country_id が countriesCfs に含まれる場合は表示
        if (!this.invoice['cfs'] && this.countriesCfs.includes(Number(this.invoice['to_country_id']))) {
          this.cfsNotice = true;
        } else {
          this.cfsNotice = false;
        }
      },

    },
  })

});